body {
  font-family: Arial, Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  padding-top: 3.5rem;
}

.checkbox-input{
  display: none;
}
.checkbox-parts{
  padding-left: 20px;
  position:relative;
  margin-right: 20px;
}
.checkbox-parts::before{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #999;
  border-radius: 4px;
}
.checkbox-input:checked + .checkbox-parts{
  color: #fff;
  font-weight: bold;
}
.checkbox-input:checked + .checkbox-parts::after{
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 5px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 3px solid #d01137;
  border-right: 3px solid #d01137;
}

div.row .border-dark {
  border-color: rgb(168, 168, 168) !important;
  /*background-color: rgb(245, 245, 245);*/
}

.searchFrame{
  position: relative;
  display: inline-block;
}

.inputBox{
  padding-right: 20px;
  width:120px;
}

.searchFrame .clearButton{
  width: 13px;
  height: 13px;
  position: absolute;
  right: 2px;
  top: 1px;
  cursor: pointer;	
}

label {
  /*background-color: rgb(224, 224, 224);*/
  font-size: 0.9rem;
  cursor:pointer;
}

.text-center {
  text-align: center;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 1rem;
  font-size: 0.85rem;;
}

[dir=ltr] ol, [dir=ltr] ul {
  padding-left: 0px;
}

.container__body {
  position: relative;
  min-height: 70vh;
  z-index: 999;
}

span.br{
  display: block;
}

.card {
  height:100%;
}
.card-text {
  font-size: 9px;
}
.text-teal {
  color: #00B5AD;
}
.center {
  text-align: center;
}
.header {
  border: none;
  margin: calc(2rem - 0.14285em ) 0em 1rem;
  padding: 0em 0em;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.2857em;
  text-transform: none;
  font-size: 2rem;
}
.card-columns {
    column-count: 4;
    column-gap: 0.5rem;
}
.breadcrumb {
  background-color: #fff;
}
.mark, mark {
  padding: .2em;
  background-color: #ffe047;
}
.more{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.more:focus{
 white-space: normal;
  width: auto;
  overflow: visible;
  text-overflow: clip;

}

.badge-word {
  padding: 8px 20px 8px 8px;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 20px;
  position:relative;
  color: #212529;
  background-color: #dbdbdb;
  display: inline-block;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.batsu3{
  font-size: 1.5rem;
  cursor: pointer;
  cursor: hand;
  color:#616161;
  font-weight: 100;
}
.close3{
  position: absolute;
  right: 5%;
  top: 6%;
}

.batsu2{
  font-size: 1.8rem;
  cursor: pointer;
  cursor: hand;
}
.close2{
  position: absolute;
  right: 15%;
  top: 10%;
}

.batsu{
  font-size: 120%;
  cursor: pointer;
  cursor: hand;
}

.close{
  position: absolute;
  top: -5px;
  right: 0;
}
.card-body {
  padding: 0.8rem;
}

.OR26 {
  position: relative;
  z-index: 0;
}

.OR26 .OR26__navigation {
  color: #fff;
  background: #000;
  position: relative;
  overflow: visible;
}

.OR26 .OR26__navigation {
  height: 73px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.OR26 .OR26__navigation:before {
  right: 100%;
}
.OR26 .OR26__navigation:after, .OR26 .OR26__navigation:before {
  content: "";
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  background: #000;
}

@media only screen and (max-width: 1024px) {
 .OR26 .OR26__logo img {
      height: 32px;
  }
}
@media only screen and (max-width: 1100px) {
 .OR26 .OR26__logo img {
      height: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .OR26 .OR26__logo img {
    height: 32px;
  }
}

.OR26 .OR26__logo img {
    height: 46px;
    width: auto;
}
.OR26 .OR26__logo img {
    display: block;
    max-width: none;
}

.OR26 .OR26__logo {
  margin-right: auto;
  margin-left: 10px;
  z-index: 1;
}

.OR26 .OR26__logo a, .OR26--mobile .OR26__logo a {
  display: block;
  padding: 2px;
  margin: 2px;
}


/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 1rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
  background-color: #777;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}


.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}